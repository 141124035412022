<template>
    <div class="c-order-row">
        <mercur-card class="pt-3" style="padding-bottom: 0 !important;" v-if="params.data">
            <merchant-data-table class="c-standard-table--row-separated" style="min-height: auto;" :options="quoteOptions" :items="quoteData" :isNotApplicationBound="true"></merchant-data-table>
            <hr>
            <ag-grid-vue v-if="rowData" class="ag-theme-balham c-order-row__orderline-table"
                         style="width: 100%; height: auto; min-height: auto;"
                         :columnDefs="columnDefs"
                         :rowData="rowData"
                         :cacheBlockSize="5"
                         :paginationPageSize="5"
                         :pagination="true"
                         :gridOptions="gridOptions"
                         @grid-ready="onGridReady"
            >
            </ag-grid-vue>
        </mercur-card>
    </div>
</template>

<script>
import Vue from 'vue'
import { AgGridVue } from 'ag-grid-vue'
import 'ag-grid-enterprise'

import MerchantDataTable from '@/components/elements/MerchantDataTable'
import Currency from '@/components/elements/Currency'

export default Vue.extend({
    name: 'QuoteRow',
    components: { AgGridVue, MerchantDataTable },
    data () {
        return {
            columnDefs: null,
            gridOptions: null,
            quoteOptions: {
                columns: {
                    'Quote #': {
                        field: 'quoteNumber',
                        link: (value, data) => this.routeQuote(data.accountId, data.cartId, data.applicationId),
                    },
                    'Date': { field: 'dateCreated' },
                    'Customer': {
                        field: 'billingInfo.address.firstName',
                        valueFormatter: (params) => {
                            try {
                                return `${params.data.billingInfo.address.firstName} ${params.data.billingInfo.address.lastName}`
                            } catch (e) {
                                return ''
                            }
                        },
                        link: (value, data) => this.routeCustomer(data.accountId, data.applicationId),
                    },
                    'lastName': { field: 'billingInfo.address.lastName', hide: true },
                    'Company': { field: 'billingInfo.address.companyName' },
                    'Phone': { field: 'billingInfo.address.phone' },
                    'Status': { field: 'status' },
                    'Total': {
                        field: 'totals.total',
                        cellRendererFramework: Currency,
                        cellRendererParams: (params) => {
                            return {
                                value: params.value,
                                type: this.params.data.currency,
                            }
                        },
                    },
                },
                pagination: false,
                headerHeight: 28,
                customCellClass: 'c-standard-table__cell--dense',
                defaultColDef: { menuTabs: [] },
            },
        }
    },
    methods: {
        onGridReady (params) {
            setTimeout(() => {
                params.api.sizeColumnsToFit()
            }, 100)
        },
        routeQuote (accountId, cartId, applicationId) {
            return {
                name: 'EditQuote',
                params: {
                    customerId: accountId,
                    cartId: cartId,
                    applicationId: applicationId,
                },
            }
        },
        routeCustomer (id, applicationId) {
            return {
                name: 'CustomerView',
                params: {
                    customerId: id,
                    applicationId: applicationId,
                },
            }
        },
    },
    computed: {
        rowData () {
            return this.params.data.cartItems
        },
        quoteData () {
            const data = JSON.parse(JSON.stringify(this.params.data))
            data.totalRows = 1
            return [data]
        },
    },

    beforeMount () {
        this.gridOptions = {
            defaultColDef: {
                sortable: true,
                filter: true,
            },
            rowHeight: 44,
            getRowClass: function (params) {
                if (params.node.rowIndex % 2 === 0) {
                    return 'c-order-row__stripped-row'
                }
            },
        }
        this.columnDefs = [
            { headerName: 'Product name', field: 'product.productName' },
            { headerName: 'SKU', field: 'product.sku' },
            { headerName: 'Weight', field: 'product.productInfo.weight' },
            { headerName: 'Production Days', field: 'product.productionDays' },
            {
                headerName: 'Product price',
                field: 'productPrice',
                cellRendererFramework: Currency,
                cellRendererParams: (params) => {
                    return {
                        value: params.value,
                        type: this.params.data.currency,
                    }
                },
            },
        ]
    },
})
</script>

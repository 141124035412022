<template>
    <div class="grid-wrapper">
        <div class="c-default-table__pagination">
            <p class="c-default-table__pagination-info">Page <span ref="currentPage"></span> of <span ref="totalPages"></span></p>
            <mercur-button class="btn c-default-table__pagination-button" @click.native="toPreviousPage"><i class="fas fa-chevron-left"></i></mercur-button>
            <mercur-button class="btn c-default-table__pagination-button" @click.native="toNextPage"><i class="fas fa-chevron-right"></i></mercur-button>
        </div>

        <ag-grid-vue class="ag-theme-balham c-order-table"
                    data-e2e="quotesTableList"
                    style="width: 100%; height: auto;"
                    :columnDefs="columnDefs"
                    :rowModelType="rowModel"
                    :gridOptions="gridOptions"
                    :getRowHeight="getRowHeight"
                    :isFullWidthCell="isFullWidthCell"
                    :fullWidthCellRenderer="fullWidthCellRenderer"
                    :frameworkComponents="frameworkComponents"
                    :pagination="true"
                    :cacheBlockSize="numberOfRows"
                    :paginationPageSize="numberOfRows"
                    suppressPaginationPanel=true
                    @pagination-changed="onPaginationChanged"
                    @grid-ready="onGridReady"
        >
        </ag-grid-vue>
    </div>
</template>

<script>
import { AgGridVue } from 'ag-grid-vue'
import 'ag-grid-enterprise'
import QuoteRow from '@/components/elements/quotes/Row'

import ApiServerSideDataSource from '@/components/utils/ApiServerSideDataSource'

export default {
    name: 'QuotesTable',
    components: {
        AgGridVue,
    },
    props: {
        items: {
            type: Array,
            default: function () {
                return []
            },
        },
        url: {
            default: null,
        },
        presetRowNumber: {
            default: 10,
        },
        params: {
            default: () => {
                return {}
            },
        },
    },
    data () {
        return {
            columnDefs: null,
            gridOptions: null,
            getRowHeight: null,
            isFullWidthCell: null,
            fullWidthCellRenderer: null,
            frameworkComponents: null,
            filterModel: {},
            rowModel: 'serverSide',
            numberOfRows: this.presetRowNumber,
        }
    },
    methods: {
        onGridReady (params) {
            window.addEventListener('resize', () => {
                this.gridApi.sizeColumnsToFit()
            })

            this.gridApi.setSortModel([{
                colId: 'dateCreated', sort: 'desc',
            }])
            this.serverSideDatasource = new ApiServerSideDataSource({
                $api: this.$api,
                url: this.url,
                getFilterModel: () => this.filterModel,
                responseHandler: (successCallback, { data }) => {
                    if (data.length === 0) {
                        params.api.showNoRowsOverlay()
                    }
                    successCallback(data, data[0].totalRows)
                    setTimeout(() => {
                        params.api.sizeColumnsToFit()
                    }, 10)
                },
            })

            this.onPaginationChanged()
            params.api.setServerSideDatasource(this.serverSideDatasource)
        },
        onPaginationChanged () {
            if (this.gridApi) {
                this.$refs.currentPage.innerHTML = this.gridApi.paginationGetCurrentPage() + 1
                this.$refs.totalPages.innerHTML = this.gridApi.paginationGetTotalPages()
            }
        },
        toPreviousPage () {
            this.gridApi.paginationGoToPreviousPage()
        },
        toNextPage () {
            this.gridApi.paginationGoToNextPage()
        },
        onModelUpdated (params) {
            params.api.sizeColumnsToFit()
        },
        refreshGrid () {
            this.gridApi.purgeServerSideCache()
        },
        applyFilter (filter) {
            this.gridApi.setFilterModel(filter)
        },
        listen () {
            this.refreshGrid()
            this.gridApi.hideOverlay()
        },
    },
    beforeMount () {
        this.gridOptions = {
            defaultColDef: {
                sortable: true,
                autoHeight: true,
                filter: 'agTextColumnFilter',
                filterParams: {
                    caseSensitive: true,
                },
            },
            headerHeight: 0,
        }
        this.columnDefs = [
            { headerName: 'Quote Id', field: 'cartId' },
            { headerName: 'Quote #', field: 'quoteNumber' },
            { headerName: 'Date Created', field: 'dateCreated' },
            { headerName: 'Customer', field: 'billingInfo.address.firstName' },
            { headerName: 'lastName', field: 'billingInfo.address.lastName', hide: true },
            { headerName: 'Company', field: 'billingInfo.address.company' },
            { headerName: 'Phone', field: 'billingInfo.address.phone' },
            { headerName: 'Address', field: 'billingInfo.address.address' },
            { headerName: 'Status', field: 'status' },
            { headerName: 'Total', field: 'totals.total' },
            { field: 'quoteAgentAssigneeId', hide: true },
            { headerName: 'Date From', field: 'dateFrom', hide: true },
            { headerName: 'Date To', field: 'dateTo', hide: true },
        ]
        this.getRowHeight = params => {
            if (!params.data.cartItems) {
                return 100
            }
            let factor = params.data.cartItems.length < 6 ? params.data.cartItems.length : 5

            if (!params.data.cartItems.length) {
                factor = 0
            }

            return 210 + (factor * 44)
        }
        this.frameworkComponents = { fullWidthCellRenderer: QuoteRow }
        this.isFullWidthCell = rowNode => {
            return rowNode.data
        }
        this.fullWidthCellRenderer = 'fullWidthCellRenderer'
    },
    mounted () {
        this.gridApi = this.gridOptions.api
        this.gridColumnApi = this.gridOptions.columnApi

        this.$bus.$on('shopWasChanged', this.listen)
    },
    destroyed () {
        this.$bus.$off('shopWasChanged', this.listen)
    },

}
</script>

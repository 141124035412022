<template>
    <div class="c-quotes">
        <v-title title="Quotes"></v-title>

        <title-box>
            <div class="row">
                <div class="col-9">
                    <h1>Quotes</h1>
                </div>
                <div class="col-3 text-right">
                    <mercur-button data-e2e="addQuoteButton" v-if="hasPermission('MerchantCentral/createOrder')" :to="{ name: 'AddQuote' }" class="btn btn-primary text-uppercase">
                        <i class="fas fa-plus"></i> Add new quote
                    </mercur-button>
                </div>
            </div>
        </title-box>

        <div class="container-fluid">
            <template v-if="hasPermission('MerchantCentral/listQuotes')">
                <mercur-card class="mb-4 mt-n4">
                    <table-search-filter
                        @change="applyFilter"
                        :has-date-range="true"
                        :adicionalRanges="adicionalRanges"
                        :filterFields="filterFields"
                        :dropdownFilters="dropdownFilters"
                        >
                    </table-search-filter>
                </mercur-card>
                <div>
                    <quotes-table :url="url" ref="table"></quotes-table>
                </div>
            </template>
            <p v-else>
                Not allowed to see this view
            </p>
        </div>
    </div>
</template>

<script>

import CONFIG from '@root/config'
import QuotesTable from '@/components/elements/quotes/Table'
import TableSearchFilter from '@/components/elements/table/SearchFilter'
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
    name: 'QuotesView',
    components: { QuotesTable, TableSearchFilter },
    computed: {
        ...mapState('shop', ['shops']),
        ...mapGetters({
            agents: 'agents/getAgentsSortedByFirstName',
        }),
        url () {
            if (!this.selectedShop) {
                return
            }
            return CONFIG.API.ROUTES.CART.OVERVIEW
        },
    },
    data () {
        return {
            filterFields: {
                'cartId': { 'column': 'cartId', 'label': 'Quote Id', 'operator': 'equals' },
                'billingInfo.address.firstName': { 'label': 'First name' },
                'billingInfo.address.lastName': { 'label': 'Last name' },
                'quoteNumber': { 'column': 'quoteNumber', 'label': 'Quote Number', 'operator': 'equals' },
            },
            dropdownFilters: [
                {
                    'column': 'status',
                    'label': 'Quote Status',
                    'operator': 'equals',
                    'values': {
                        'Open': 'OPEN',
                        'Checked Out': 'CHECKED_OUT',
                        'Rejected': 'REJECTED',
                        'closed': 'CLOSED',
                        'finalized': 'FINALIZED',
                        'approved': 'APPROVED',
                    },
                },
                {
                    'column': 'quoteAgentAssigneeId',
                    'label': 'Assigned to',
                    'operator': 'equals',
                    'values': {
                        'Me': this.$store.state.auth.user.accountId,
                    },
                },
            ],
            adicionalRanges: {
                'Today': [new Date(new Date().setHours(1, 0, 0, 0)), new Date()],
                '1 day ago': [this.getDateRange(1), new Date()],
                '3 days ago': [this.getDateRange(3), new Date()],
                '7 days ago': [this.getDateRange(7), new Date()],
                '1 month ago': [this.getDateRange(30), new Date()],
            },
        }
    },
    methods: {
        ...mapActions('agents', ['fetchAllAgents']),
        applyFilter (data) {
            this.$refs.table.applyFilter(data)
        },
        getDateRange (offset) {
            let today = new Date()
            let dateOffset = new Date()
            dateOffset.setDate(today.getDate() - offset)
            return dateOffset
        },
    },
    created () {
        this.fetchAllAgents().then(() => {
            const assigneeDropdownFilter = this.dropdownFilters.find(({ column }) => column === 'quoteAgentAssigneeId')
            const newValues = {
                ...assigneeDropdownFilter.values,
                ...Object.fromEntries(this.agents.map(agent => [`${agent.firstName}${agent.infix ? ' ' + agent.infix : ''} ${agent.lastName}`, agent.accountId])),
            }
            this.$set(assigneeDropdownFilter, 'values', newValues)
        })
    },
}
</script>
